import React, { useEffect, useState } from "react";
import {
  Tabs,
  Blockie,
  Address,
  Loader,
  Button,
} from "decentraland-ui";
import { Row, Col, Card } from "react-bootstrap";
import CustomItemsPage from "./CustomItemsPage";
import NPCsPage from "./NPCsPage";

export const DashboardPage: React.FC<any> = ({ userAddress, room }) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [view, setView] = useState("dashboard");

  useEffect(() => {
    if (room) {
      setLoadingPage(false);
      setView("dashboard");
    }
    return () => {};
  }, [room]);

  const getDashboard = (): JSX.Element => {
    return (
      <>
            <Tabs>
        <Tabs.Tab onClick={()=>{setView("dashboard")}}>Angzaar Plaza Admin</Tabs.Tab>
        {view === "dashboard" && <Tabs.Tab active>Dashboard</Tabs.Tab>}
        {view === "custom-items" && <Tabs.Tab onClick={()=>{setView("custom-items")}} active>Custom Items</Tabs.Tab>}
        <Tabs.Tab>
          <Blockie scale={3} seed={userAddress}>
            <Address value={userAddress} strong />
          </Blockie>
        </Tabs.Tab>
      </Tabs>

        <div>
          <Row style={{ padding: "0 5% 0 5%" }}>
            {/* <Col style={{ padding: "0 5% 0 5%" }}> */}
            <Card style={{ width: "20%", margin:'0 3em 0 3em' }}>
            <Card.Body>
                <Card.Title>Custom Items</Card.Title>
                <Card.Text>Manage Custom 3D Items.</Card.Text>
                <Button primary onClick={() => setView("custom-items")}>
                Edit
                </Button>
            </Card.Body>
            </Card>
            {/* </Col>
            <Col style={{ padding: "0 5% 0 5%" }}> */}
            <Card style={{ width: "20%", margin:'0 3em 0 3em' }}>
            <Card.Body>
                <Card.Title>NPCs</Card.Title>
                <Card.Text>Manage NPCs.</Card.Text>
                <Button primary onClick={() => setView("npcs")}>
                Edit
                </Button>
            </Card.Body>
            </Card>
            {/* </Col> */}
          </Row>
        </div>
      </>
    );
  };

  // Main getView function
  const getView = (): JSX.Element | null => {
    switch (view) {
      case "dashboard":
        return getDashboard();

     case 'custom-items':
        return <CustomItemsPage userAddress={userAddress} room={room} onBack={() => setView("dashboard")} />;
      
     case 'npcs':
        return <NPCsPage userAddress={userAddress} room={room} onBack={() => setView("dashboard")} />;

    

        
        default:
        return <p>Invalid view selected.</p>;
    }
  };

  return (
    <div className="dcl page fullscreen">
         {getView()}
    </div>
  );
};
