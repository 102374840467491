import React, {useState} from "react";
// import ReservationDetails from "./components/ReservationDetae ils";
// import UploadDropzone from "./components/UploadDropzone";
import { LoginPage } from "./components/LoginPage";
import { Room } from "colyseus.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import { DashboardPage } from "./components/Dashboard";

const App: React.FC = () => {
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [room, setRoom] = useState<Room | null>(null); // Colyseus room state

  return (
    <div className="Page-story-container">
            {!userAddress ? (
        <LoginPage
        setUserAddress={setUserAddress}
        setRoom={setRoom}
      />
      ) : 

      <DashboardPage userAddress={userAddress} room={room} />
    }
      </div>
  );
};

export default App;
