
export default {
    ENV:"Production",
    DEV_SERVER:"ws://localhost:5353",
    PROD_SERVER:"wss://angzaar-plaza.dcl-iwb.co/ws",
    IGNORE_FILE_TYPES:".blend,.exe",
    IGNORE_FILES:"",
    IGNORE_DIRECTORIES:"temp,node_modules,.github,.vscode,bin",
    MAX_TOTAL_SIZE: "300MB",
    MAX_FILE_SIZE: "50MB",
    COLYSEUS_ROOM: 'angzaar_plaza_admin'
}
